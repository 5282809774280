<template>
    <div class="settings">
        <van-nav-bar class="bar" :border="false" :title="$t('donation.title')" placeholder left-arrow @click-left="onClickLeft" />
        <div class="endowment-banner__container">
            <p>{{ $t('donation.total_donations_amount') }}：<span>{{ (Number(donateData.totalDonation) + Number(config?.platform_onation_irtual_data.value)).toFixed(2) }}</span></p>
            <img class="endowment-banner" src="../../../assets/endowment.png" alt="">
        </div>
        <div class="endowment-data">
            <p>{{ $t('donation.my_donation_amount') }}：<span>{{ donateData.total }}</span></p>
            <p>{{ $t('donation.donation_count') }}：<span>{{ donateData.count }}</span> {{ $t('donation.num') }}</p>
        </div>
        <div class="endowment-html" v-html="config?.event_purpose.value"></div>
        <div class="endowment-btn">
            <van-button class="btn-bg" plain type="danger" round block>{{ $t('donation.invitations_sent') }}</van-button>
            <van-button type="danger" round block @click="show = true">{{ $t('donation.donate_now_button') }}</van-button>
        </div>
        <van-popup class="endowment-p" v-model="show" style="width: 80%;background-color: #242229;" round>
            <div class="endowment-popup">
                <p class="endowment-popup__title">{{ $t('donation.title') }}</p>
                <p class="endowment-popup__balance">{{ $t('user.balance') }}：{{ user.balance }}</p>
                <van-field class="endowment-cell" :placeholder="$t('donation.donation_amount_input')" type="number" v-model="amount" />
                <div style="height: 24px;"></div>
                <van-button class="submit-btn" type="danger" round block :loading="loading" @click="_donate">{{ $t('donation.donate_now_button') }}</van-button>
            </div>
        </van-popup>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { donate, donationData } from '@/api'

export default {
    data() {
        return {
            show: false,
            amount: '',
            loading: false,
            donateData: {},
        };
    },
    computed: {
        ...mapState(['user', 'config']),
    },
    created() {
        this._donationData();
    },
    methods: {
        ...mapActions(['fetchUser']),
        _donationData() {
            donationData().then(({ code, data }) => {
                if (code === 200) {
                    this.donateData = data;
                }
            });
        },
        onClickLeft() {
            this.$router.back();
        },
        _donate() {
            if (!this.amount) {
                this.$toast(this.$t('donation.donation_amount_input'));
                return;
            }
            this.loading = true;
            donate({ amount: this.amount }).then(({ code, message }) => {
                this.loading = false;
                this.$toast(message);
                if (code === 200) {
                    this.show = false;
                    this._donationData();
                    this.fetchUser();
                }
            }).catch(() => {
                this.loading = false;
            });
        }
    },
}
</script>

<style lang="less" scoped>
.endowment-banner__container {
    display: flex;
    align-items: center;
    justify-content: center;
    p {
        color: #fff;
        font-size: 20px;
        position: absolute;
        text-align: center;
        span {
            font-size: 32px;
            font-weight: 700;
        }
    }
}
.endowment-popup__title {
    font-size: 18px;
    text-align: center;
    margin-bottom: 24px;
}
.endowment-popup__balance {
    margin-bottom: 24px;
    text-align: center;
    color: #909399;
}
.endowment-popup {
    padding: 16px 24px 24px;
}
.endowment-btn {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 12px;
    display: flex;
    > button {
        &:first-child {
            margin-right: 12px;
        }
    }
}
.endowment-html {
    padding: 12px 12px 70px;
    /deep/ * {
        background: none !important;
    }
}
.endowment-data {
    // background-color: #fff;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    padding: 12px;
    color: var(--font-color);
    span {
        color: #ee0a24;
    }
}
.endowment-banner {
    width: 100%;
}
.settings {
    min-height: 100vh;
    background: url(../../../assets/login_bg.png) left top no-repeat
        var(--bg-color);
    background-size: 100%;
    .bar {
        background: none;
    }
}
.bar /deep/ .van-nav-bar__left .van-icon {
    color: var(--font-color);
}
.bar /deep/ .van-nav-bar__title {
    color: var(--font-color);
}
.btn-bg {
    background: var(--bg-color);
}
@media only screen and (min-width: 750px) {
    .settings {
        background: none;
        padding: 42px;
        .bar {
            line-height: 30px;
            z-index: 0;
            /deep/ .van-nav-bar__content {
                height: 30px;
            }
            /deep/ .van-nav-bar__left {
                padding: 0;
                .van-icon {
                    font-size: 28px;
                }
            }
            /deep/ .van-nav-bar__title {
                font-size: 28px;
            }
        }
        .endowment-banner__container {
            margin: 20px 0;
            p {
                font-size: 20px;
                span {
                    font-size: 32px;
                    font-weight: 700;
                }
            }
        }
        .endowment-data {
            font-size: 18px;
            padding: 12px;
        }
        .endowment-html {
            padding: 12px 12px 70px;
            font-size: 18px;
            line-height: 30px;
        }
        .endowment-btn {
            position: initial;
            padding: 12px;
            > button {
                height: 60px;
                line-height: 60px;
                font-size: 16px;
            }
        }
        .endowment-p {
            width: 520px !important;
            border-radius: 30px;
        }
        .endowment-popup__title {
            font-size: 18px;
            margin-bottom: 24px;
        }
        .endowment-popup__balance {
            margin-bottom: 24px;
            font-size: 26px;
        }
        .endowment-cell {
            width: 100%;
            margin: 30px 0;
            height: 78px;
            line-height: 78px;
            background: #f0f3f8;
            border-radius: 16px;
            padding: 0 20px;
            font-size: 18px;
        }
        .endowment-popup {
            padding: 16px 24px 24px;
        }
        .submit-btn {
            width: 319px;
            height: 60px;
            line-height: 60px;
            background: linear-gradient(270deg, #a44afb 0%, #f85dc6 100%);
            border-radius: 55px 55px 55px 55px;
            font-size: 16px;
            margin: 0 auto;
            border: 0;
        }
        .btn-bg {
            background: #242229;
        }
    }
}
</style>
